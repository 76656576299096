// .page-link-custom {
//   height: 36px;
//   width: 36px;
//   background: rgba(17, 141, 255, 0.1);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 50%;
//   font-weight: 500;
//   font-size: 16px;
//   color: #118dff;
// }
.pagination-container {
  display: flex;
  justify-content: end;
}
.pagination-dropdown {
  margin-right: 10px;
  border-radius: 20px;
}
