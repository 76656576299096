/* //override */
.multi-city-timiline {
  text-transform: capitalize;
  padding: 0px !important;
}
.info-color {
  color: #d734bd !important;
}

.info-color:hover {
  color: #fff !important;
}
.info-color:visited {
  color: #fff !important;
}
.inquiry-filters-container {
  display: flex;
  /* justify-content: flex-end; */
}

.filter-item {
  margin-left: 8px;
  /* width: 21%; */
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}
.ant-picker.ant-picker-range {
  border-radius: 1rem;
  height: 35px;
}
.ant-collapse.ant-collapse-icon-position-end.appointment_collapse_table {
  border: none;
  background: #eeeeee;
  border-radius: 0px;
}

.ct-chevron-icon {
  font-size: 18px;
}

.displayed-img-cont {
  display: flex;
  /* justify-content: space-between; */
  overflow: auto;
  /* flex-wrap: wrap; */
  margin-top: 26px;
}

img.ant-image-img.displayed-image {
  width: 100px;
  height: 100px;
  padding: 5px
}

.ant-select-selector{
  height: 4rem !important;
  overflow: auto !important;
}

.inner.uk-grid {
  height: auto !important;
}