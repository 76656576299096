/* main { margin: auto; max-width: 960px; } */

/* .table-container {
  margin: 10px;
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
} */

tr.expandable > td {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, .2);
  padding: 0;
}

tr.expandable > td > .inner {
  margin: 15px;
  overflow: hidden;
}